import logo from "./images/logo-no-text.svg";
import "./App.css";

function App() {
  const aboutDescription =
    "With over 28 years of experience in the online health and fitness industry, we pride ourselves on being the best coaching company in the world. We’ve helped over 100,000 individuals achieve their goal weight through expertly crafted plans tailored to their unique hormonal profile. We are committed to equipping each individual with the tools necessary for sustained, long-term weight management that aligns with their individual lifestyle and preferences.";

  return (
    <div className="App">
      <div className="App-header">
        <img src={logo} className="cc-logo" alt="logo" />
        <div className="slogan">
          Elevating Health and Fitness Through Unrivaled Coaching Excellence
          Globally
        </div>
        <div className="short-description">{aboutDescription}</div>
      </div>

      {/* <div className="About-section App-section">
        <div className="boxed-description">{aboutDescription}</div>
      </div> */}

      <div className="Contact-section App-section">
        <div className="contact-section-title"></div>
        <div className="contact-buttons-holder">
          <a href="mailto: support@committedcoaches.com">
            <div className="contact-button email-button">
              <div className="email-icon"></div>
              <div className="email-text">support@committedcoaches.com</div>
            </div>
          </a>
        </div>
      </div>
      <div className="footer">
        <div className="copyright-text">
          Copyright © 2024 COMMITTED COACHES LLC. All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default App;
